<template>
    <div v-if="presentModal">
        <div class="popup-modal">
			<div class="modal-interior">
				<div class="modal-close">
					<span @click="closeModal()">X</span>
				</div>
				<div class="modal-content">

					<div class="contents">
						<p class="modal-text">{{message}}</p>
					</div>

				</div>
			</div>
		</div>
    </div>
</template>
<style scoped src="./Modal.css"></style>
<script src="./Modal.js"></script>
