const LandingPage = () => import('./pages/landing-page/LandingPage.vue');
const MintingComponent = () => import('./pages/minting/Minting.vue');
const InternalOnly = () => import('./pages/internal-only/InternalOnly.vue');
const GameHouseManagement = () => import('./pages/game-house-management/GameHouseManagement.vue');
const OniMansionGame = () => import('./pages/oni-mansion-game/OniMansionGame.vue');
const PreSale = () => import('./pages/pre-sale/PreSale.vue');
const TrialGame = () => import('./pages/trial-game/TrialGame.vue');
const TrialMintHouse = () => import('./pages/trial-game/TrialMintHouse.vue');
const AirDropMatic = () => import('./pages/air-drop-matic/AirDropMatic.vue');
const TermsUse = () => import('./pages/terms-of-use/TermsUse.vue');
const PrivacyPolicy = () => import('./pages/privacy-policy/PrivacyPolicy.vue');
const BabySpiritStaking = () => import('./pages/staking/BabySpiritStaking.vue');
const MansionMintV2 = () => import('./pages/mansion-mint/MansionMintV2.vue');

import { RouterGuard } from './route.guard';

export const VUE_ROUTES = [
  { path: '/', component: LandingPage },
  {
    path: '/pre-sale',
    component: PreSale,
    beforeEnter: RouterGuard.redirectHome,
  },
  {
    path: '/mint',
    component: PreSale,
  },
  {
    path: '/minting/:account/:ids/',
    component: MintingComponent,
    beforeEnter: RouterGuard.onlyNFTOwner,
  },
  {
    path: '/contract-management',
    component: InternalOnly,
    beforeEnter: RouterGuard.onlyCreator,
  },
  {
    path: '/game-house-management',
    component: GameHouseManagement,
    // beforeEnter: RouterGuard.onlyCreator,
  },
  // {
  //   path: '/trial-game/:nftAddress/:nftID',
  //   component: TrialGame,
  // },
  {
    path: '/airdrop-matic',
    component: AirDropMatic,
  },
  // {
  //   name: 'oni-game-main',
  //   path: '/oni-mansion-game',
  //   component: OniMansionGame,
  // },
  {
    name: 'oni-game-specific-mansion',
    path: '/oni-mansion-game/:contract_address/:nft_id',
    component: OniMansionGame,
  },
  {
    path: '/terms-of-use',
    component: TermsUse,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/baby-spirit-staking',
    component: BabySpiritStaking,
  },
  {
    path: '/mansion-mint',
    beforeEnter: RouterGuard.redirectHome,
  },
  {
    path: '/mansion-preview/:id',
    beforeEnter: RouterGuard.redirectURL,
  },
  {
    path: '/mansion-song/:song',
    beforeEnter: RouterGuard.redirectURL,
  },
  {
    path: '/mansion-banner/:id',
    beforeEnter: RouterGuard.redirectURL,
  },
];
