import { ethers } from 'ethers';
import { ERROR_MESSAGES } from '../consts/Messages';
import { GAME_ERROR_CODE, GAME_NETWORK, RPC_GAME } from '../consts/Web3Consts';
import { getNFTContract, getWeb3 } from '../web3_utils';

export class Web3GameService {
  constructor() {
    this.web3 = null;
    this.houseGameContract = null;
    this.oniCoinContract = null;
    this.airDropContract = null;
    this.account = null;
    this.provider = null;
    this.balance = 0;
    this.connected = false;
    this.valuePerAmmount = {
      1: '66600000000000000',
      2: '133200000000000000',
      3: '199800000000000000',
    };

    if (this.constructor.instance) {
      return this.constructor.instance;
    }
    this.constructor.instance = this;
  }

  /**
   * This method gets all configuration for the Web3 service.
   * @returns {Promise<account>}
   */
  async getConfig() {
    if (window?.ethereum) {
      const config = await getWeb3();
      // this.ethersProvider = await new ethers.providers.JsonRpcProvider(RPC_GAME);
      this.web3 = config.web3;
      this.provider = config.provider;

      const connected = await this.checkNetwork();
      if (!connected) {
        throw { code: GAME_ERROR_CODE.WRONG_NETWORK, message: ERROR_MESSAGES.WRONG_NETWORK_POLYGON };
      }

      this.account = (await this.web3.eth.getAccounts())[0];

      const [houseGame, oniCoin, hauntedHouse, airDrop, babySpirit] = await Promise.all([
        getNFTContract(this.web3, 'HouseGame'),
        getNFTContract(this.web3, 'OniCoin'),
        getNFTContract(this.web3, 'HauntedHouse'),
        getNFTContract(this.web3, 'AirDropMaticOni'),
        getNFTContract(this.web3, 'BabySpirit'),
      ]);

      this.houseGameContract = houseGame;
      this.oniCoinContract = oniCoin;
      this.hauntedHouseContract = hauntedHouse;
      this.airDropContract = airDrop;
      this.babySpiritContract = babySpirit;

      return this.account;
    } else {
      return false;
    }
  }

  async checkNetwork() {
    if (!this.web3 && !window?.ethereum) return false;
    // const { chainId } = await this.ethersProvider.detectNetwork();
    return this.web3.eth.net.getId().then((networkId) => {
      //local only
      // networkId = GAME_NETWORK;
      if (networkId === GAME_NETWORK) {
        this.connected = true;
      } else {
        this.connected = false;
      }

      return this.connected;
    });
  }

  /**
   *
   * @param { string } attrID the Attr ID.
   * @returns Attr Type.
   */
  async getAttrType(attrID) {
    if (this.account) {
      return this.houseGameContract.methods
        .idToType(attrID)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } attrID The Attr ID.
   * @returns Attr Price.
   */
  async getAttrPrice(attrID) {
    if (this.account) {
      return this.houseGameContract.methods
        .idToPrice(attrID)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } nftAddressEquipAttr The contract Address that this NFT belongs.
   * @param { string } nftIDEquipAttr The NFT ID specific to the given contract address
   * @param { string } attrIDEquipAttr The Item ID.
   * @param { string } slotPositionIDEquipAttr The Slot position of that Specific Contract.
   * @returns
   */
  async equipAttrToHouse(nftAddressEquipAttr, nftIDEquipAttr, attrIDEquipAttr, slotPositionIDEquipAttr) {
    if (this.account) {
      return this.houseGameContract.methods
        .equipAttrToHouse(nftAddressEquipAttr, nftIDEquipAttr, attrIDEquipAttr, slotPositionIDEquipAttr)
        .send({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } nftAddress The contract Address.
   * @param { string } nftID The NFT ID.
   * @returns
   */
  async doDailyCheckIn(nftAddress, nftID) {
    if (this.account) {
      return this.houseGameContract.methods
        .dailyCheckIn(nftAddress, nftID)
        .send({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  /**
   *
   * @param { string } nftAddress The contract Address.
   * @param { string } nftID The NFT ID.
   * @returns
   */
  async getLastDailyCheckIn(nftAddress, nftID) {
    if (this.account) {
      return this.houseGameContract.methods
        .nftLastCheckIn(nftAddress, nftID)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getMissionCounter(nftAddress, nftId) {
    if (this.account) {
      return this.houseGameContract.methods
        .missionCounter(nftAddress, nftId)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } contractDailySpecificHaunting NFT Contract.
   * @param { string } nftIDDailySpecificHaunting NFT ID.
   * @param { string } dayDailySpecificHaunting Game Day
   * @returns
   */
  async dailyGenericHauntingMission(
    contractDailySpecificHaunting,
    nftIDDailySpecificHaunting,
    dayDailySpecificHaunting
  ) {
    if (this.account) {
      return this.houseGameContract.methods
        .dailyGenericHauntingMission(
          contractDailySpecificHaunting,
          nftIDDailySpecificHaunting,
          dayDailySpecificHaunting
        )
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } contractDailySpecificHaunting NFT Contract.
   * @param { string } nftIDDailySpecificHaunting NFT ID.
   * @param { string } dayDailySpecificHaunting Game Day
   * @returns
   */
  async dailySpecificHauntingMission(
    contractDailySpecificHaunting,
    nftIDDailySpecificHaunting,
    dayDailySpecificHaunting
  ) {
    if (this.account) {
      return this.houseGameContract.methods
        .dailySpecificHauntingMission(
          contractDailySpecificHaunting,
          nftIDDailySpecificHaunting,
          dayDailySpecificHaunting
        )
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   *
   * @param { string } contractMissionCounter  NFT Contract.
   * @param { string } nftIDOMissionCounter NFT ID.
   * @returns
   */
  async missionCounter(contractMissionCounter, nftIDOMissionCounter) {
    if (this.account) {
      return this.houseGameContract.methods
        .missionCounter(contractMissionCounter, nftIDOMissionCounter)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async nftIdToLootBoxPositionToLootedAttrId(nftContractAddress, contractOpenLootBox, nftIDOpenLootBox) {
    if (this.account) {
      return this.houseGameContract.methods
        .nftIdToLootBoxPositionToLootedAttrId(nftContractAddress, contractOpenLootBox, nftIDOpenLootBox)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async nftToHouseSlotToAttrID(nftAddressHouseAttr, oniIDHouseAttr, attrPositionHouseAttr) {
    if (this.account) {
      return this.houseGameContract.methods
        .nftToHouseSlotToAttrID(nftAddressHouseAttr, oniIDHouseAttr, attrPositionHouseAttr)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async getBalanceOfHauntedHouse() {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .balanceOf(this.account.toString())
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getHouseHameAddressForHauntedHouse() {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .houseGameAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getHauntedHouseAddress() {
    if (this.account) {
      return this.houseGameContract.methods
        .hauntedHouseAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   * Returns the house attributes for a specific Contract and NFT Address
   * @param { string } nftContractAddress
   * @param { string } nftId
   * @returns
   */
  async getHouseAttrs(nftContractAddress, nftId) {
    if (this.account) {
      return this.houseGameContract.methods
        .getHouseAttrs(nftContractAddress, nftId)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async alreadyMintedHouseForNFT(nftContractAddress, nftID) {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .alreadyMintedHouseForNFT(nftContractAddress, nftID)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async isMintingEnabled() {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .mintEnabled()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async alreadyMintedHouseForThisNFT(nftAddress, nftID) {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .alreadyMintedHouseForNFT(nftAddress, nftID)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getCurrentDayUnix() {
    if (this.account) {
      return this.houseGameContract.methods
        .currentDayUnix('0')
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getDayInSec() {
    if (this.account) {
      return this.houseGameContract.methods
        .dayInSeconds('0')
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getLastBlockTimestamp() {
    if (this.account) {
      return this.houseGameContract.methods
        .getBlockTimestamp()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getFirstPart() {
    if (this.account) {
      return this.houseGameContract.methods
        .getFirstPart()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getCheckCondition() {
    if (this.account) {
      return this.houseGameContract.methods
        .getCheckDailyConditional()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getOniCoinBalance(address) {
    if (this.account) {
      return this.oniCoinContract.methods
        .balanceOf(address)
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async isHouseAttrAvailableInStore(houseAttrId) {
    if (this.account) {
      return this.houseGameContract.methods
        .alreadySoldAttr(houseAttrId)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getHouseGameAddress() {
    if (this.account) {
      return this.oniCoinContract.methods
        .houseGameContractAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getOniNFTAddress() {
    if (this.account) {
      return this.houseGameContract.methods
        .oniAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getOniCoinAddressForhauntedHouse() {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .oniCoinAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getOniCoinAddress() {
    if (this.account) {
      return this.houseGameContract.methods
        .oniCoinAddress()
        .call({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async buyLootBoxItem(nftAddress, nftID) {
    if (this.account) {
      return this.houseGameContract.methods
        .buyLootBoxItem(nftAddress, nftID)
        .send({ from: this.account, value: '10000000000000000000' })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot) {
    if (this.account) {
      return this.houseGameContract.methods
        .claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async updateOwners(nftContractAddresses, nftIDs, ownerAddresses) {
    if (this.account) {
      return this.houseGameContract.methods
        .updateOwners(nftContractAddresses, nftIDs, ownerAddresses)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async updateOwnersAirDrop(nftContractAddresses, nftIDs, ownerAddresses) {
    if (this.account) {
      return this.airDropContract.methods
        .updateNFTOwners(nftContractAddresses, nftIDs, ownerAddresses)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  async claimDailyBonus(nftContractAddress, nftID) {
    if (this.account) {
      return this.houseGameContract.methods
        .claimDailyBonus(nftContractAddress, nftID)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async claimWeeklyBonus(nftContractAddress, nftID) {
    if (this.account) {
      return this.houseGameContract.methods
        .claimWeeklyBonus(nftContractAddress, nftID)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }

  async hauntSpecificNFT(haunterContractAddress, haunterNFTID, victimContractAddress, victimNFTID, eventDay) {
    if (this.account) {
      return this.houseGameContract.methods
        .hauntSpecificNFT(haunterContractAddress, haunterNFTID, victimContractAddress, victimNFTID, eventDay)
        .send({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async hauntGenericOni(haunterContractAddress, haunterNFTID, victimContractAddress, victimNFTID, eventDay) {
    if (this.account) {
      return this.houseGameContract.methods
        .hauntGeneric(haunterContractAddress, haunterNFTID, victimContractAddress, victimNFTID, eventDay)
        .send({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async openLootBox(contractOpenLootBox, nftIDOpenLootBox) {
    if (this.account) {
      return this.houseGameContract.methods
        .openLootBox(contractOpenLootBox, nftIDOpenLootBox)
        .send({ from: this.account })
        .then((res) => {
          console.log(res);
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async approveToStore() {
    if (this.account) {
      return this.oniCoinContract.methods
        .approveToStore()
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async checkApprovedToStore(playerAddress = this.account) {
    if (this.account) {
      return this.oniCoinContract.methods
        .approvedToStore(playerAddress)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async enableHauntedHouseMinting() {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .enableMinting()
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setAttrsPriceBatch(attrIdToSetBatch, attrPriceToSetBatch) {
    console.log(attrIdToSetBatch, attrPriceToSetBatch);
    if (this.account) {
      return this.houseGameContract.methods
        .setAttrsPrice(attrIdToSetBatch, attrPriceToSetBatch)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setAttrPrice(nftID, price) {
    if (this.account) {
      return this.houseGameContract.methods
        .setAttrPrice(nftID, price)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setAttrType(nftID, type) {
    if (this.account) {
      return this.houseGameContract.methods
        .setAttrType(nftID, type)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async buyHouseAttrs(nftContractToBuyAttr, nftIDToBuyAttr, houseAttrIdToBuyAttr, ammountToBuyAttr) {
    if (this.account) {
      return this.houseGameContract.methods
        .buyHouseAttrs(nftContractToBuyAttr, nftIDToBuyAttr, houseAttrIdToBuyAttr, ammountToBuyAttr)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setNFTIdsSpecificMission(oniIds, eventDay, nftContractAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .setNFTIdsSpecificMission(oniIds, eventDay, nftContractAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setHauntedHouseAddress(hauntedHouseAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .setHauntedHouseAddress(hauntedHouseAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setHouseGameAddressForHauntedHouse(houseGameAddress) {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .setHouseGameAddress(houseGameAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setHouseGameAddress(houseGameAddress) {
    if (this.account) {
      return this.oniCoinContract.methods
        .setHouseGameContractAddress(houseGameAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setMissionCounter(nftAddressMissionCounter, oniIDMissionCounter, missionCounterInput) {
    if (this.account) {
      return this.houseGameContract.methods
        .setMissionCounter(nftAddressMissionCounter, oniIDMissionCounter, missionCounterInput)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async mintHauntedHouse(nftAddress, nftID) {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .mint(nftAddress, nftID)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setMaticBeingSent(newValue) {
    console.log('@@@@@@@@@@@@@@@@@');
    console.log(this.account);
    console.log('@@@@@@@@@@@@@@@@@');
    if (this.account) {
      return this.airDropContract.methods
        .setMaticBeingSent(newValue)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setOniCoinAddressForHauntedHouse(oniCoinAddress) {
    if (this.account) {
      return this.hauntedHouseContract.methods
        .setOniCoinAddress(oniCoinAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  /**
   * This method returns an already playing user, stored in this mapping.
   * @param { string } nftAddress Contract address.
   * @param { string } nftId NFT ID.
   * @returns Wallet Address of the owner.
   */
  async mappedNFTToOwner(nftAddress, nftId) {
    if (this.account) {
      return this.houseGameContract.methods
        .mappedNFTToOwner(nftAddress, nftId)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setOniCoinAddress(oniCoinAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .setOniCoinAddress(oniCoinAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async setOniAddress(oniAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .setOniAddress(oniAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async addNFTContractAddress(nftContractAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .addNFTContract(nftContractAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async getTimesHauntedNft(nftAddress, nftId) {
    if (this.account) {
      return this.houseGameContract.methods
        .timesNFTHasBeenHaunted(nftAddress, nftId)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async currentDayInt() {
    if (this.account) {
      return this.houseGameContract.methods
        .currentDayInt('0')
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async updateNFTOwner(nftContractAddress, nftID, ownerAddress) {
    if (this.account) {
      return this.houseGameContract.methods
        .updateNFTOwner(nftContractAddress, nftID, ownerAddress)
        .send({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async unpauseHauntedHouseContract() {
    if (this.account) {
      this.hauntedHouseContract.methods
        .unpause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async pauseHauntedHouseContract() {
    if (this.account) {
      this.hauntedHouseContract.methods
        .pause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async unpauseOniCoin() {
    if (this.account) {
      this.oniCoinContract.methods
        .unpause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async pauseOniCoin() {
    if (this.account) {
      this.oniCoinContract.methods
        .pause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }

  async mintCoins(address) {
    if (this.account) {
      return this.oniCoinContract.methods
        .mint(address, 500)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async nftLastCheckIn(contractAddress, nftId, gameDay) {
    if (this.account) {
      return this.houseGameContract.methods
        .nftLastCheckIn(contractAddress, nftId, gameDay)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async claimedDailyBonusToday(contractAddress, nftId, gameDay) {
    if (this.account) {
      return this.houseGameContract.methods
        .claimedDailyBonusToday(contractAddress, nftId, gameDay)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  async mintBabySpirit(babySpiritAddress) {
    if (this.account) {
      return this.babySpiritContract.methods
        .mint(babySpiritAddress)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async unpauseBabySpirit() {
    if (this.account) {
      return this.babySpiritContract.methods
        .unpause()
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async transferOni(from, to, id) {
    if (this.account) {
      return this.babySpiritContract.methods
        .transferFrom(from, to, id)
        .send({ from: this.account })
        .then((res) => {
          return res.toString();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async claimedWeeklyBonusToday(contractAddress, nftId, gameDay) {
    if (this.account) {
      return this.houseGameContract.methods
        .claimedWeeklyBonusToday(contractAddress, nftId, gameDay)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
  /**
   * Checks if the player has already haunted a GENERIC NFT.
   * @param { string } contractAddress
   * @param { string} nftId
   * @param { string } gameDay
   * @returns
   */
  async hasGenericHaunt(contractAddress, nftId, gameDay) {
    if (!this.account) return false;
    try {
      const has = await this.houseGameContract.methods
        .dailyGenericHauntingMission(contractAddress, nftId, gameDay)
        .call({ from: this.account });
      return !has;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  /**
   * Checks if the player has already haunted a SPECIFIC NFT.
   * @param { string } contractAddress
   * @param { string} nftId
   * @param { string } gameDay
   * @returns
   */
  async hasSpecificHaunt(contractAddress, nftId, gameDay) {
    if (!this.account) return false;
    try {
      const has = await this.houseGameContract.methods
        .dailySpecificHauntingMission(contractAddress, nftId, gameDay)
        .call({ from: this.account });
      return !has;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  /**
   * Returns the IDs for specific NFTs that the player can haunt.
   * @param { string } contractAddress NFT Contract Address.
   * @returns
   */
  async specificHauntMissionNFTIDs(contractAddress) {
    const gameDay = await this.currentDayInt();
    if (this.account) {
      return this.houseGameContract.methods
        .getSpecificHauntMissionNFTIDs(gameDay, contractAddress)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }

  signNonce(publicAddress, nonce) {
    return this.web3.eth.personal.sign(`OniSquadSignature-${nonce}`, publicAddress);
  }

  /**
   * Checks if the player is allowed to perform the 7 day streak bonus.
   * @param { string } contractAddress
   * @param { string} nftId
   * @returns
   */
  async canUserGetStreakBonus(contractAddress, nftId) {
    if (!this.account) return false;
    try {
      return await this.houseGameContract.methods
        .canUserGetStreakBonus(contractAddress, nftId)
        .call({ from: this.account });
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async interactedNFTToOwner(contractAddress, nftId) {
    if (this.account) {
      return this.houseGameContract.methods
        .interactedNFTToOwner(contractAddress, nftId)
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  }
}
