// CHAIN ID
const STAGING_NETWORK = 80001;
const LOCAL_NETWORK = 1337;
const ETHEREUM_NETWORK = 1;
const POLYGON_NETWORK = 80001;

export const GAME_NETWORK =
  process.env.VUE_APP_GAME_ENVIRONMENT === 'production'
    ? POLYGON_NETWORK
    : process.env.VUE_APP_GAME_ENVIRONMENT === 'staging'
    ? STAGING_NETWORK
    : LOCAL_NETWORK;
export const ONI_NETWORK =
  process.env.VUE_APP_GAME_ENVIRONMENT === 'production'
    ? ETHEREUM_NETWORK
    : process.env.VUE_APP_GAME_ENVIRONMENT === 'staging'
    ? STAGING_NETWORK
    : LOCAL_NETWORK;

export const METAMASK_ERROR_CODE = {
  REJECTED: 4001,
  METAMASK_ERROR: -32603,
};

export const GAME_ERROR_CODE = {
  WRONG_NETWORK: 4002,
};
export const GAME_CONTRACT_ADDRESS = {
  GAME_HOUSE: '0x6f94E518A68fE0Fc2E4A68497a2b22565020a30D',
  ONI_NFT: '0x0ee7bf57733e3da8c015a0e7e395d641fac512d6',
};
