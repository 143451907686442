import { ERROR_MESSAGES } from '../consts/Messages';
import { GAME_CONTRACT_ADDRESS, ONI_NETWORK } from '../consts/Web3Consts';
import { getNFTContract, getWeb3 } from '../web3_utils';
import OniNFTABI from '../../public/contracts/OniNFT.json';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';

export class Web3NFTService {
  constructor() {
    this.web3 = null;
    this.oniNFT = null;
    this.houseGameContract = null;
    this.oniCoinContract = null;
    this.account = null;
    this.provider = null;
    this.balance = 0;
    this.connected = false;
    this.valuePerAmmount = {
      1: '0',
      2: '0',
      3: '0',
    };
  }

  /**
   * This method gets all configuration for the Web3 service.
   * @returns {Promise<account>}
   */
  getConfig() {
    return new Promise((resolve, reject) => {
      try {
        getWeb3().then((config) => {
          this.web3 = config.web3;
          this.provider = config.provider;
          this.checkNetwork().then((connected) => {
            // Mainnet
            if (connected) {
              this.web3.eth.getAccounts().then((accounts) => {
                this.account = accounts[0];
                getNFTContract(this.web3, 'OniNFT').then((oniNFT) => {
                  this.oniNFT = oniNFT;
                  this.getBalance().then(() => {
                    resolve({ accounts });
                  });
                });
              });
            } else {
              reject(ERROR_MESSAGES.WRONG_NETWORK);
            }
          });
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }

  checkNetwork() {
    return this.web3.eth.net.getId().then((networkId) => {
      //local only
      // networkId = ONI_NETWORK;
      if (networkId === ONI_NETWORK) {
        this.connected = true;
      } else {
        this.connected = false;
      }

      return this.connected;
    });
  }
  getBalance() {
    return new Promise((resolve, reject) => {
      try {
        this.oniNFT.methods
          .balanceOf(this.account)
          .call({
            from: this.account,
          })
          .then((balance) => {
            this.balance = parseInt(balance);
            resolve(this.balance);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } catch (error) {
        this.checkNetwork();
        reject(error);
        throw new Error(error);
      }
    });
  }

  async checkOwnerTokens(account) {
    const oniIDS = [];

    for (let i = 0; i < this.balance; i++) {
      const oniId = await this.oniNFT.methods.tokenOfOwnerByIndex(account, i).call({
        from: account,
      });
      oniIDS.push(oniId.toString());
    }
    return { oniIDS, account };
  }

  // Created this method to not mess up with what was working in the pre-sale day.
  checkOwnerTokensContractManagement(account) {
    return new Promise((resolve, reject) => {
      try {
        this.oniNFT.methods
          .balanceOf(account)
          .call({
            from: account,
          })
          .then(async (balance) => {
            const oniIDS = [];

            for (let i = 0; i < balance; i++) {
              const oniId = await this.oniNFT.methods.tokenOfOwnerByIndex(account, i).call({
                from: account,
              });
              oniIDS.push(oniId.toString());
            }
            resolve({ oniIDS, account });
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } catch (error) {
        reject(error);
        throw new Error(error);
      }
    });
  }

  async mint(amount, successCallback, errorCallback) {
    // const web3Modal = new Web3Modal()
    //   // const connection = await web3Modal.connect()
    //   // const provider = new ethers.providers.Web3Provider(connection)
    //   await window.ethereum.enable()
    //   const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   const { chainId } = await provider.getNetwork()
    //   console.log("CHAIN ID: ",chainId); // 42
    //   const signer = provider.getSigner()
    //   console.log("Account:", await signer.getAddress());
    //   const houseGameContract = new ethers.Contract("0x67524c43D512ceC351d426e4EB18d4b8e1447f92", HouseGameJson.abi, signer);

    // await houseGameContract.updateNFTOwner(nftContractAddress, nftID, ownerAddress);
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const oniNFTContract = new ethers.Contract(GAME_CONTRACT_ADDRESS.ONI_NFT, OniNFTABI.abi, signer);
      const response = await oniNFTContract.mint(amount, { value: this.valuePerAmmount[amount] });
      successCallback(response);
    } catch (error) {
      console.error(error);
      let message = ERROR_MESSAGES.UNKOWN_ERROR;
      if (error.code === 4001) {
        message = ERROR_MESSAGES.USER_CANCELED;
        errorCallback(message);
      }
      if (error?.code === -32603 && error.data.message.includes('MAX_BY_MINT')) {
        message = ERROR_MESSAGES.MAX_NUMBER_ONIS_BEFORE_MINT;
        errorCallback(message);
      }
      console.log(error?.error?.message);
      if (error?.error?.code === -32603 && error?.error?.message.includes('MAX_BY_MINT')) {
        message = ERROR_MESSAGES.MAX_NUMBER_ONIS_BEFORE_MINT;
        errorCallback(message);
      }
    }
    // await this.oniNFT.methods
    //   .mint(amount)
    //   .send({ from: this.account, value: this.valuePerAmmount[amount] })
    //   .on('receipt', () => {
    //     successCallback();
    //     this.getBalance();
    //   })
    //   .on('error', (error) => {
    //     console.log(error);
    //     let message = ERROR_MESSAGES.UNKOWN_ERROR;
    //     if (error.code === 4001) {
    //       message = ERROR_MESSAGES.USER_CANCELED;
    //     }
    //     errorCallback(message);
    //   });
  }
  async mintGas(amount, successCallback, errorCB) {
    this.oniNFT.methods
      .mint(amount)
      .estimateGas({ from: this.account, value: this.valuePerAmmount[amount] }, (err, gas) => {
        console.log(err, gas);
        if (err) errorCB(err);
        if (gas) successCallback(gas);
      });
  }
  async ownerOf(id) {
    if (this.account) {
      return this.oniNFT.methods
        .ownerOf(id.toString())
        .call({ from: this.account })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async unpause() {
    if (this.account) {
      this.oniNFT.methods
        .unpause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async pause() {
    if (this.account) {
      this.oniNFT.methods
        .pause()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async maxByMint() {
    if (this.account) {
      return this.oniNFT.methods
        .MAX_BY_MINT()
        .call({ from: this.account })
        .then((res) => {
          return parseInt(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async totalMint() {
    if (this.account) {
      return this.oniNFT.methods
        .totalMint()
        .call({ from: this.account })
        .then((res) => {
          return parseInt(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async creatorAddress() {
    if (this.account) {
      return this.oniNFT.methods
        .creatorAddress()
        .call({ from: this.account })
        .then((res) => {
          return res === this.account;
        })
        .catch((err) => {
          console.error(err);
          return false;
        });
    }
  }
  async withdrawAll() {
    if (this.account) {
      this.oniNFT.methods
        .withdrawAll()
        .send({ from: this.account })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }

  async setMax(number) {
    this.oniNFT.methods
      .setMAX_BY_MINT(number.toString())
      .send({ from: this.account })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  }
  async setMAX_ELEMENTS(number) {
    this.oniNFT.methods
      .setMAX_ELEMENTS(number.toString())
      .send({ from: this.account })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  }
  async setPRICE(number) {
    this.oniNFT.methods
      .setPRICE(number.toString())
      .send({ from: this.account })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  }
}
