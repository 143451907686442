import Modal from './components/modal/Modal.vue';
import Loader from './components/loader/Loader.vue';

export default {
  name: 'App',
  data() {
    return {
      showModal: false,
      modalMessage: '',
      loading: false,
    };
  },
  components: {
    Modal,
    Loader,
  },
  methods: {
    close() {
      this.showModal = false;
      this.modalMessage = '';
    },
  },
  mounted() {
    this.$emitter.on('open-modal-root', (message) => {
      this.showModal = true;
      this.modalMessage = message;
    });
    this.$emitter.on('toggle-loader', (loading) => {
      this.loading = loading;
    });
  },
};
