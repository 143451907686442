import { Web3NFTService } from './services/Web3NFTService';
import lodash from 'lodash';
import axios from 'axios';
import { API_URL } from './pages/oni-mansion-game/consts/GameUrl';
import { Web3GameService } from './services/Web3GameService';
import json_consts from '../../consts/universal_conts.json';

export const RouterGuard = {
  redirectHome: (to, from, next) => {
    next('/');
  },
  onlyNFTOwner: (to, from, next) => {
    const web3 = new Web3NFTService();
    web3.getConfig().then(() => {
      web3.checkOwnerTokens(web3.account).then((response) => {
        if (response.oniIDS.length === 0) {
          next('/');
        }
        const onis = response.oniIDS.join(',');
        const isEqual = lodash.isEqual(lodash.sortBy(response.oniIDS), lodash.sortBy(to.params.ids.split(',')));
        if (isEqual) {
          next();
        } else {
          next({
            path: `/minting/${response.account}/${onis}`,
            params: { account: response.account, ids: onis },
          });
        }
      });
    });
  },
  onlyCreator: (to, from, next) => {
    const web3 = new Web3NFTService();
    web3.getConfig().then(() => {
      web3.creatorAddress(web3.account).then((response) => {
        if (!response) {
          next('/');
        } else {
          next();
        }
      });
    });
  },
  onlyManagers: async (to, from, next) => {
    const web3GameService = new Web3GameService();
    await web3GameService.getConfig();
    console.log(web3GameService.account);
    try {
      const api = axios.create({
        // withCredentials: true,
        baseURL: API_URL + '/api/',
      });
      const { data } = await api.get(`auth/is-manager?wallet=${web3GameService.account}`);
      console.log(data);
      if (data) {
        next();
      } else {
        next('/');
        alert('You are not a manager.');
      }
    } catch (error) {
      console.log(error);
      next('/');
    }
  },

  redirectURL(to, from, next) {
    console.log(to.path);
    if (to.path.includes('/mansion-preview')) {
      console.log(1);
      window.location.href = `${json_consts.MANSION_PREVIEW_URL}/HousePreview.html?id=${to.params.id}`;
    } else if (to.path.includes('/mansion-song')) {
      console.log(2);
      window.location.href = `${json_consts.MANSION_SONG_URL}/${to.params.song}`;
    } else if (to.path.includes('/mansion-banner')) {
      console.log(2);
      window.location.href = `${json_consts.TWITTER_BANNER_IMAGES}/${to.params.id}.png`;
    }
  },
};
