export const ERROR_MESSAGES = {
  USER_CANCELED: 'You have rejected the transaction, you can only buy an Oni if you finish the transaction.',
  MAX_NUMBER_ONIS: 'You minted the max allowed for this address.',
  MAX_NUMBER_ONIS_BEFORE_MINT:
    'Your address can contain at most 3 onis. Please make sure you are trying to mint a number that puts your address at a max of 3 onis.',
  WRONG_NETWORK: 'Please switch to the "Ethereum Mainnet" network.',
  WRONG_NETWORK_POLYGON: 'Please switch to the "Polygon Mumbai" network.',
  WRONG_NETWORK_POLYGON_RELOAD: 'Please switch to the "Polygon Mumbai" network and refresh your browser.',
  NOT_YOUR_NFT: 'It seems this is not your NFT.',
  UNKOWN_ERROR:
    'An unknown error has occurred, please try refreshing the browser, check MetaMask and let us know about this error in our discord.',
  INSUFFICIENT_FUNDS:
    "Sorry, you don't have enough ETH in your wallet to mint. Each Oni costs .0666 ETH and additional ETH to cover the gas costs. You can use https://etherscan.io/gastracker to track when gas prices are lower.",
  CHARACTER_SELECT:
    'Something happened while loading the characters. Try again later, please. If the error persists, contact us on discord.',
  LEAVE_A_MESSAGE: 'You should leave a message to this NFT.',
  '401': 'You session expired, please login again.',
};

export const GAME_MESSAGES = {
  NO_COINS_TO_BUY: 'You do not have enough coins to purchase this.',
  TRAIT_ALREADY_ACTIVE: 'This Attribute is already active.',
  APPROVE_STORE_TRANSACTIONS: 'You must approve the store to be able to buy attributes for your mansion.',
  USER_REJECTED_TX: 'You have rejected the transaction.',
  RESET_YOUR_ACCOUNT:
    'You must reset your metamask account to finish this transaction. This is not a game bug, it is a bug in MetaMask. You can ask for assistance on our discord.',
  LOGIN_REQUIRED:
    'You must login with Google to perform this action. After you login, close this message and try again.',
  ALREADY_LIT_CANDLE: 'You already lit a candle! You can only light one candle per house.',
  YOUR_CANDLE_WAS_LIT: 'Your candle was lit! Your friend will soon see your candle.',
  USE_THE_URL: "For the Beta Test you should use someone else's URL to make the Haunt.",
  HAUNT_SUCCESS: "We sent your NFT to haunt your friend's house!",
  CLOSE_LOADING:
    'Hey! By closing the loading you may face some weird behaviors since the loading is waiting for an event to occour.',
  ALREADY_HAVE_TRAIT: `You already have something equipped on this House Slot, make sure you intend to buy another one. You can swap between them whenever you want.`,
  NO_RECORDS_AVAILABLE: `Looks like there are no records available.`,
  SHARE_URL: `Your url was added to your clipboard! \nOni Mansion players can haunt you from this url. When they do, you get tokens and they get tokens :) \n\nNon Oni Mansion players can come and light a candle at your url without metamask, matic or any crypto knowledge. All they need to do is click a button.\n\nAfter you hit a certain number of candles lit something spooky will happen.`,
  HAUNT_NEW_DAY_STARTED: `A new day has started and you must complete a daily checkin before haunting someone.`,
  MISSION_ALREADY_COMPLETED: `You already finished this mission for today :)`,
  WEEKLY_BONUS_REQUIREMENTS:
    'You must complete three missions each day (Daily check-in, generic haunt and specific haunt) for 7 days straight to claim this 7 day streak bonus.',
  DAILY_BONUS_REQUIREMENTS:
    'You must complete the daily check-in, generic haunt and specific haunt for today to claim this daily bonus.',
  WAITING_FOR_LOGIN: 'You must sign in with your metamask in order to play the game.',
  NO_MORE_ATTRIBUTES_SWAP: 'Swapping mansion attributes has been turned off for mansion minting.',
};
