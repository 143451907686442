import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';

const getWeb3 = () =>
  new Promise((resolve, reject) => {
    detectEthereumProvider()
      .then((provider) => {
        provider;
        if (provider) {
          provider.request({ method: 'eth_requestAccounts' });
          try {
            const web3 = new Web3(window.ethereum);
            resolve({ web3, provider });
          } catch (error) {
            reject(error);
          }
        }
        reject(new Error('Install Metamask'));
      })
      .catch(() => {
        reject(new Error('Install Metamask'));
      });
  });

const getNFTContract = async (web3, filename) => {
  const res = await fetch(`/contracts/${filename}.json`);
  const OniNFTJson = await res.json();
  const networkId = await web3.eth.net.getId();
  const deployedNetwork = OniNFTJson.networks[networkId];
  return new web3.eth.Contract(OniNFTJson.abi, deployedNetwork && deployedNetwork.address);
};

export { getWeb3, getNFTContract };
