export default {
  name: 'Modal',
  props: {
    message: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.showModal,
    };
  },
  computed: {
    presentModal() {
      return (this.show = this.showModal);
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit('closeModalInstance');
    },
  },
};
