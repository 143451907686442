<template>
  <div id="app">
    <Loader :loading="loading"></Loader>
    <Modal :message="modalMessage" :showModal="showModal" @close-modal-instance="close"></Modal>
    <router-view></router-view>
  </div>
</template>

<script src="./App.js"></script>
<style src="./assets/css/reset.css"> </style>
<style src="./assets/css/flipclock.css"></style>
<style src="./assets/css/style2.css"></style>
<style src="./App.css"></style>
