import { createApp } from 'vue';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import { VUE_ROUTES } from './routes';
import emitterPlugin from './emmiter'; // ATTENTION HERE

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes: VUE_ROUTES, // short for `routes: routes`
});

createApp(App)
  .use(router)
  .use(emitterPlugin)
  .mount('#app');
